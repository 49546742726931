import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import logo from '../assets/logo.png';
import '../styles/Student.css';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../innerComponents/LoadingSpinner';
import {baseUrl} from '../data/data';

function Student() {
    const [registerNumber, setRegisterNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/admin/get-result-details?registerNumber=${registerNumber}`, {});
            setIsLoading(false);
            if(response.data){
                window.open(response.data?.imageurl, '_blank');
            }
        } catch (error) {
            setIsLoading(false);
            alert('Error!')
        }
    };
    return (
        <>
            <Helmet>
                <title>Result - NSF</title>
            </Helmet>
            <div className="stdnav">
                <div className="logosec">
                    <Link to='/'><img src={logo} alt="logo" /></Link>
                </div>
            </div>
            {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
            <form className="stdmain" onSubmit={handleSubmit}>
                <div className="stdcard">
                    <div className="head">
                        <span>Get Result</span>
                    </div>
                    <div className="stdcardmain">
                        <div className="input">
                            <input
                                type="text"
                                name='registerNumber'
                                value={registerNumber}
                                onChange={(e) => setRegisterNumber(e.target.value)}
                                required='required'
                                placeholder='Roll Number'
                            />
                        </div>
                        <div className="button">
                            <button type='submit'>Submit</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className="foot"><span>Copyright ©2024 Nilambur Skills Foundation</span></div>   
        </>
    );
}

export default Student;