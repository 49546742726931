import '../styles/WhatsappButton.css';
import { Link } from 'react-router-dom';
import { MdWhatsapp } from 'react-icons/md';
import React from "react";

function WhatsappButton(props){
    return(
        <div className="whatsapp">
            <Link to={props.contacts?.whatsapp} className="btn">
                <MdWhatsapp/>
            </Link>
        </div>
    )
}
export default WhatsappButton;