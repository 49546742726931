import React from 'react'
import '../styles/HomeCard.css';
import card1 from '../assets/card1.png';
import card2 from '../assets/card2.png';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';

function HomeCard() {
    return (
        <>
            <div className='homecard'>  
                <div className="sec1">
                    <div className="mycard">
                        <div className="cardtext1">
                            <div className='text'>
                                <span className='coursedetails'>Pg Diploma</span>
                                <span className='coursedetails'>Courses</span>
                            </div>
                            <div className='iconbutton'>
                                <Link to='/courses' className='arrow'><MdArrowForward/></Link>
                            </div>
                        </div>
                        <div className="card-image">
                            <img src={card2} alt=''/>
                        </div>
                    </div>
                </div>
                <div className="sec2">
                    <div className="mycard">
                        <div className="cardtext2">
                            <div className='text'>
                                <span className='coursedetails'>Diploma</span>
                                <span className='coursedetails'>Courses</span>
                            </div>
                            <div className='iconbutton'>
                                <Link to='/courses' className='arrow'><MdArrowForward/></Link>
                            </div>
                        </div>
                        <div className="card-image">
                            <img src={card1} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeCard