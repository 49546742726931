import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function EditNotification() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [title, settitle] = useState('');
    const [date, setdate] = useState('');
    const [category, setCategory] = useState('');
    const options = [
      { id: '1', value: 'Circulars' },
      { id: '2', value: 'Examinations' },
      { id: '3', value: 'Events' },
    ];
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        axios.get(`${baseUrl}/admin/get-notification-details?id=${id}`)
            .then(response => {
                settitle(response.data?.title);
                setdate(response.data?.date);
                setCategory(response.data?.category);
                setDescription(response.data?.description);
            })
            .catch(error => {
                console.error('Error', error);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.put(
            `${baseUrl}/admin/edit-notification?id=${id}`,
            { title, date, description, category }
        ).then(result => {
            setIsLoading(false);
            navigate('/admin/view-notifications')
        }).catch(error => {
            setIsLoading(false);
            alert('Error!')
        });
    };

    return (
        <>
        <Sidebar/>
        <AdminNav/>
        <Helmet>
            <title>Admin - Nilambur Skills Foundations</title>
        </Helmet>
        <div className="addpage">
            {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
            <form className="adminform" onSubmit={handleSubmit}>
                <div className="pagehead"><span>Edit Notification</span></div>
                <div className="field">
                    <div className='label' htmlFor="">Notification Title</div>
                    <div className="input"><input type="text" name='title' value={title} onChange={(e) => settitle(e.target.value)} required="required" placeholder='Enter Notification Title' /></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Date</div>
                    <div className="input"><input type="date" name='date' value={date} onChange={(e) => setdate(e.target.value)} required="required" placeholder='Enter Date' /></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Category</div>
                    <div className="input">
                        <input type="text" id="category" name='category' list="options" value={category} onChange={(e) => {setCategory(e.target.value)}} required="required" placeholder='Choose a Category'/>
                        <datalist id="options">
                            {options.map((option) => (
                                <option key={option.id} value={option.value} />
                            ))}
                        </datalist>
                    </div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Description</div>
                    <div className="textarea"><textarea rows='4' name='description' value={description} onChange={(e) => setDescription(e.target.value)} required="required" placeholder='Enter Description'/></div>
                </div>                
                <button type='submit'>EDIT</button>
            </form>
        </div>
        <AdminFooter/>
        </>
    );
}

export default EditNotification;
