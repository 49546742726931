import { Helmet } from 'react-helmet';
import Sidebar from './SideBar';
import '../../styles/admin/HomeAdmin.css';
import AdminNav from './AdminNav';
import AdminFooter from './AdminFooter';
import { MdContacts, MdEdit, MdStar } from 'react-icons/md';
import React, {useEffect, useState} from "react";
import axios from 'axios';
import { baseUrl } from '../../data/data';

function HomeAdmin() {
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(`${baseUrl}/admin/get-dashboard-count`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); 

  return (
    <>
      <Sidebar/>
      <AdminNav/>
      <Helmet>
          <title>Admin - Nilambur Skills Foundations</title>
      </Helmet>
      <div className="homeadmin">
        <div className="head">Dash Board</div>
        <div className="cards">
          <div className="icard course">
            <div className="icon">
              <MdEdit/>
            </div>
            <div className="count">
              <div className="num">{data?.course}</div>
              <div className="text">Total Courses</div>
            </div>
          </div>
          <div className="icard contact">
            <div className="icon">
              <MdContacts/>
            </div>
            <div className="count">
              <div className="num">{data?.contact}</div>
              <div className="text">Total Team Members</div>
            </div>
          </div>
          <div className="icard testimonials">
            <div className="icon">
              <MdStar/>
            </div>
            <div className="count">
              <div className="num">{data?.testimonial}</div>
              <div className="text">Total Testimonials</div>
            </div>
          </div>
        </div>
      </div>
      <AdminFooter/>
    </>
  );
}

export default HomeAdmin;