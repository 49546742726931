import React, { useEffect, useRef } from 'react';
import '../styles/LogoSlider.css';
import img1 from '../assets/sliderlogos/logo1.png';
import img2 from '../assets/sliderlogos/logo2.png';
import img3 from '../assets/sliderlogos/logo3.png';
import img4 from '../assets/sliderlogos/logo4.png';
import img5 from '../assets/sliderlogos/logo5.png';
import img6 from '../assets/sliderlogos/logo6.png';
import img7 from '../assets/sliderlogos/logo7.png';
import img8 from '../assets/sliderlogos/logo8.png';
import img9 from '../assets/sliderlogos/logo9.png';
import img10 from '../assets/sliderlogos/logo10.png';
import img11 from '../assets/sliderlogos/logo11.png';
import img12 from '../assets/sliderlogos/logo12.png';
import img13 from '../assets/sliderlogos/logo13.jpg';
import img14 from '../assets/sliderlogos/logo14.png';
import img15 from '../assets/sliderlogos/logo15.jpg';
import img16 from '../assets/sliderlogos/logo16.png';
import img17 from '../assets/sliderlogos/logo17.jpg';
import img18 from '../assets/sliderlogos/logo18.png';
import img19 from '../assets/sliderlogos/logo19.jpg';
import img20 from '../assets/sliderlogos/logo20.png';
import img21 from '../assets/sliderlogos/logo21.jpg';
import img22 from '../assets/sliderlogos/logo22.png';
import img23 from '../assets/sliderlogos/logo23.png';
import img24 from '../assets/sliderlogos/logo24.jpg';
import img25 from '../assets/sliderlogos/logo25.jpg';
import img26 from '../assets/sliderlogos/logo26.png';
import img27 from '../assets/sliderlogos/logo27.jpg';
import img28 from '../assets/sliderlogos/logo28.png';
import img29 from '../assets/sliderlogos/logo29.webp';
import img30 from '../assets/sliderlogos/logo30.jpg';
import img31 from '../assets/sliderlogos/logo31.jpg';
import img32 from '../assets/sliderlogos/logo32.jpg';
import img33 from '../assets/sliderlogos/logo33.png';


const LogoSlider = () => {
  const logosRef = useRef(null);

  useEffect(() => {
    const copy = logosRef.current.cloneNode(true);
    logosRef.current.parentNode.appendChild(copy);
  }, []);

  return (
    <div className="sliderlogos">
      <div ref={logosRef} className="sliderlogos-slide s2">
        <div className="logo-img s2"><img src={img1} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img2} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img3} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img4} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img5} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img6} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img7} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img8} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img9} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img10} alt="logo1" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img11} alt="logo11" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img12} alt="logo12" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img13} alt="logo13" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img14} alt="logo14" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img15} alt="logo15" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img16} alt="logo16" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img17} alt="logo17" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img18} alt="logo18" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img19} alt="logo19" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img20} alt="logo20" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img21} alt="logo21" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img22} alt="logo22" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img23} alt="logo23" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img24} alt="logo24" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img25} alt="logo25" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img26} alt="logo26" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img27} alt="logo27" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img28} alt="logo28" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img29} alt="logo29" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img30} alt="logo30" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img31} alt="logo31" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img32} alt="logo32" /></div>
        <div className="space"></div>
        <div className="logo-img s2"><img src={img33} alt="logo32" /></div>
        <div className="space"></div>
      </div>
    </div>
  );
};

export default LogoSlider;

