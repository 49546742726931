import { Helmet } from 'react-helmet';
import React, {useEffect, useState} from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import {baseUrl} from '../../../data/data';

function ViewNotifications() {
    const [data, setData] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    useEffect(() => {
      axios.get(`${baseUrl}/admin/get-notification`)
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
            console.error('Error ', error);
        });
    }, [data]);

    const deleteCourse = (id, e) => {
        e.preventDefault();
        setIsDeleting(true);
        axios.delete(`${baseUrl}/admin/delete-notification?id=${id}`)
            .then(result => {
                setIsDeleting(false);
                setData(data.filter(course => course._id !== id));
            }).catch(error => {
                setIsDeleting(false);
                alert('Error!')
            });
    };
    return (
        <>
        <Sidebar/>
        <AdminNav/>
        <Helmet>
            <title>Admin - Nilambur Skills Foundations</title>
        </Helmet>
        <div className="addpage">      
            {isDeleting && <div className="loading-overlay"><LoadingSpinner /></div>}     
            <div className={`viewtable ${isDeleting ? 'blur' : ''}`}>
                <div className="pagehead"><span>All Notifications</span></div>
                {data?.map((item, index) => (
                    <div className="viewcard" key={index}>
                        <div className="item">
                            <span className="head">Title</span>
                            <span className="content">{item.title}</span>
                        </div>
                        <div className="item">
                            <span className="head">Date</span>
                            <span className="content">{item.date}</span>
                        </div>
                        <div className="item">
                            <span className="head">Category</span>
                            <span className="content">{item.category}</span>
                        </div>
                        <div className="item">
                            <span className="head">Description</span>
                            <span className="content">{item.description}</span>
                        </div>
                        <div className="buttons">
                            <Link className='btnlink' to={`/admin/edit-notification/${item._id}`}><button className='actionbutton edit'>Edit</button></Link><br/>
                            <button className='actionbutton delete' onClick={(e) => deleteCourse(item._id, e)}>Delete</button>
                        </div>
                    </div>
                ))} 
            </div>
        </div>
        <AdminFooter/>
        </>
    );
}

export default ViewNotifications;