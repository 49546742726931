import React from 'react';
import '../styles/HomeAbout2.css';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';

const HomeAbout2 = () => {
  return (
    <>
        <div className='homeabout2'>
            <div className="container">
                <div className="row">
                    <div className="text">
                      <span className='plan'>Planning For</span>
                      <span className='neetjee'>DIPLOMA / PG DIPLOMA?</span>
                      <div className='horizontalline'></div>
                      <span className='span'>YOUR DREAMS ARE IMPORTANT FOR US</span>
                      <span className='span'>CHOOSE YOUR STARTING POINT</span>
                      <Link to='/courses' className="btn" style={{textDecoration:'none',color:'black'}}>READ MORE<span className='btnarrow'><MdArrowForward/></span></Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default HomeAbout2;
