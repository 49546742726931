import { Helmet } from 'react-helmet';
import '../styles/CourseDetails.css';
import { Link } from 'react-router-dom';
import { MdArrowForward } from 'react-icons/md';
import img from '../assets/courses/image4.avif';
import { useLocation } from 'react-router-dom';
import img1 from '../assets/courses/img1.jpg';
import img2 from '../assets/courses/img2.jpg';
import img3 from '../assets/courses/img3.jpg';
import img4 from '../assets/courses/img4.jpg';
import img5 from '../assets/courses/img5.jpg';
import img6 from '../assets/courses/img6.jpg';
import img7 from '../assets/courses/img7.jpg';
import img8 from '../assets/courses/img8.jpg';
import img9 from '../assets/courses/img9.jpg';
import img10 from '../assets/courses/img10.jpg';

function CourseDetails({course, courses}){
    const images = [img1,img2,img3,img10,img5,img6,img7,img8,img9];

    const location = useLocation();
    const { index } = location.state || {};
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "d00347c9-5fcc-4995-a5f9-906cfacbd253");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          event.target.reset();
        } else {
          alert('Error!');
        }
      };
      const repeatedDataWithImages = courses.map((item, index) => ({
        data: item,
        image: images[index % images.length],
      }));
      const relatedCourses = repeatedDataWithImages?.filter(item => item.data.category === course.category && item.data.route !== course.route);
    return(
        <>  
            <Helmet>
                <title>NSF COURSES</title>
            </Helmet> 
            <div className="coursehead">{course.coursename}</div>
            <div className="coursemain">
                <div className="coursecontent">
                    <p>
                    ID: {index}
                    </p>
                    <div className="courseimage">
                        <img src={images[index]} alt="" />
                    </div>
                    <div className="coursedisc">
                        {course.description}
                    </div>
                </div>
                <div className="courseenquiry">
                    <form onSubmit={onSubmit} className="enqform">
                        <div className="quickhead">QUICK ENQUIRY</div>
                        <div className="quicktextboxes">
                            <div className="textbox"><div className="inner"><input name="Name" required="required" type="text" placeholder='Name'/></div></div>
                            <div className="textbox"><div className="inner"><input name="Email" required="required" type="email" placeholder='Email'/></div></div>
                            <div className="textbox"><div className="inner"><input name="Phone" required="required" type="text" placeholder='Mobile'/></div></div>
                        </div>
                        <div className="quicktextare">
                            <div className="textarea"><textarea rows='3' name="Message" required="required" placeholder='Message'></textarea></div>
                        </div>
                        <button type='submit' className="quicksendbutton">SEND NOW <MdArrowForward style={{fontSize:'1.5rem',marginLeft:'20px'}}/></button>
                    </form>
                </div>
            </div>
            <div className="coursehorizontal"></div>
            <div className="coursehorizontal"></div>
            {relatedCourses.length > 0 && (
                <>
                    <div className="relatehead">Related Courses</div>
                    <div className='courcescard'>
                        {relatedCourses?.map((item, index) => (
                            <Link className="ccard" to={item.data.route} key={index} state={{ index: index % images.length }}>
                                <div className="cimg">
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="ctext">
                                    {item.data.coursename}
                                </div>
                            </Link>
                        ))}
                    </div>
                </>
            )}
            <div style={{height:'50px'}}></div>
        </>
    )
}
export default CourseDetails;