import React from "react";
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import HomeApplySec from "../innerComponents/HomeApplySec";
import '../styles/Faculty.css';
import LoadingSpinner from '../innerComponents/LoadingSpinner';

function Faculties(props) {
  const { contacts, team } = props;
  const principals = team?.filter(member => member.position.toLowerCase() === 'principal') || [];
  const programManagers = team?.filter(member => member.position.toLowerCase() === 'program manager') || [];
  // const otherStaff = team?.filter(member => member.position.toLowerCase() !== 'principal' || member.position.toLowerCase() !== 'program manager') || [];
  const otherStaff = team?.filter(member => 
    member.position.toLowerCase() !== 'principal' && 
    member.position.toLowerCase() !== 'program manager'
  ) || [];
  

  return (
    <>
      {contacts && team ? (
        <>
          <Helmet>
            <title>Our Team - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Team NSF</span>
          </div>
          <div className='facmain'>
            {principals.length > 0 && (
              <div>
                <h2 className="section-title">Principal</h2>
                <div className="faccardmain">
                  {principals.map((principal, index) => (
                    <div key={index} className="faccard">
                      <div className="facimage">
                        <img src={principal.imgUrl} alt={principal.facultyName} />
                      </div>
                      <div className="facname">
                        {principal.facultyName}
                      </div>
                      <div className="facpos">
                        {principal.position}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {programManagers.length > 0 && (
              <div>
                <h2 className="section-title">Program Manager</h2>
                <div className="faccardmain">
                  {programManagers.map((principal, index) => (
                    <div key={index} className="faccard">
                      <div className="facimage">
                        <img src={principal.imgUrl} alt={principal.facultyName} />
                      </div>
                      <div className="facname">
                        {principal.facultyName}
                      </div>
                      <div className="facpos">
                        {principal.position}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {otherStaff.length > 0 && (
              <div>
                <h2 className="section-title">Others</h2>
                <div className="faccardmain">
                  {otherStaff.map((principal, index) => (
                    <div key={index} className="faccard">
                      <div className="facimage">
                        <img src={principal.imgUrl} alt={principal.facultyName} />
                      </div>
                      <div className="facname">
                        {principal.facultyName}
                      </div>
                      <div className="facpos">
                        {principal.position}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* <div className="faccardmain">
              {team.map((item, index) => (
                <div key={index} className="faccard">
                  <div className="facimage">
                    <img src={item.imgUrl} alt={item.imageurl} />
                  </div>
                  <div className="facname">
                    {item.facultyName}
                  </div>
                  <div className="facpos">
                    {item.position}
                  </div>
                </div>
              ))}
            </div> */}
          </div>
          <HomeApplySec />
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <Helmet>
            <title>Our Team - NSF</title>
          </Helmet>
          <NavBar />
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Team NSF</span>
          </div>
          <LoadingSpinner />
          <HomeApplySec/>
          <Footer/>
        </>
      )}
    </>
  );
}

export default Faculties;
