import '../styles/ApplyButton.css';
import { Link } from 'react-router-dom';

function ApplyButton(){
    return(
        <div className="apply">
            <Link to='/contact' className="btn">
                <p>Apply Now</p>
            </Link>
        </div>
    )
}
export default ApplyButton;