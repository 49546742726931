import React, { useState,useEffect,useCallback } from 'react';
import '../styles/HomeNewsEvents.css';
import { MdArrowForward } from 'react-icons/md';

const HomeNewsEvents = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationClass, setAnimationClass] = useState('');
    
    const goToNext = useCallback(() => {
        setAnimationClass('slide-out-left');
        setTimeout(() => {
            const isLastImage = currentIndex === props.news.length - 1;
            const newIndex = isLastImage ? 0 : currentIndex + 1;
            setCurrentIndex(newIndex);
            setAnimationClass('slide-in-right');
        }, 500);
      },[currentIndex, props.news?.length]);
      
    useEffect(() => {
        const interval = setInterval(goToNext, 2000); 
        return () => clearInterval(interval);
    }, [goToNext]);

    return (
        <div className="homenewsevents">
            <div className="sec">
                <div className="news">
                    <div className="head">
                        <span>News & Events</span>
                    </div>
                    <div className="image">
                        <div className={`${animationClass}`} key={currentIndex}>
                            <img src={props.news[currentIndex]?.imgUrl} alt="" />
                        </div>
                        <div className="date">{props.news[currentIndex]?.date}</div>
                        <div className="title">
                            {props.news[currentIndex]?.news}
                        </div>
                    </div>
                    <div className="allbutton">
                        READ ALL NEWS <span className='btnarrow'><MdArrowForward/></span>
                    </div>
                </div>
                
                
            </div>
        </div>
    );
};

export default HomeNewsEvents;