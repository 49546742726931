import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
// import { auth } from "../../controllers/firebaseconfig";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { Helmet } from 'react-helmet';
import img from '../../assets/logo.png';
import img1 from '../../assets/backgrounds/cmsbg.png';
import '../../styles/admin/LoginAdmin.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { baseUrl } from "../../data/data";

function LoginAdmin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [, setCookie] = useCookies(['accessToken']);

  const { isAuthenticated } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const from = location.state?.from?.pathname || '/admin';
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  const handleSubmit = (e) => {
      e.preventDefault();
      axios.post(
          `${baseUrl}/user/login`,
          { email, password }
      ).then(result => {
          if (result.data.success) {
              setCookie('accessToken', result.data.accessToken, { path: '/', });
              navigate('/admin');
          } else {
              alert(result.data.message);
          }
      }).catch(error => {
          alert('Error!')
      });
  };
  return (
    <>
      <Helmet>
          <title>Login - Nilambur Skills Foundations</title>
      </Helmet>
      <div className="loginmain">
        <div className="loginimage">
          <img src={img1} alt="" />
        </div>
        <form className="loginform" onSubmit={handleSubmit}>
            <div className="loginlogo">
                <img src={img} alt="logo"/>
            </div>
            <div className='label' htmlFor="">Username</div>
            <div className="input"><input type="email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} required="required" placeholder='UserName'/></div>
            <div className='label' htmlFor="">Password</div>
            <div className="input"><input type="password" name='password' value={password} onChange={(e) => setPassword(e.target.value)} required="required" placeholder='Password'/></div>
            <Link className='forgot' to=''>Forgot Password</Link>
            <button type='submit'>LOGIN</button>
        </form>
      </div>
    </>
  );
}

export default LoginAdmin;