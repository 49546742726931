import React from 'react';
import '../styles/TestimonialWidget.css';

function TestimonialWidget(props) {
  let obj = props.testimonial;
  const test = obj?.test;
  const imgUrl = obj?.imgUrl;
  const studentName = obj?.studentName;
  const studentCourse = obj?.studentCourse;
  return (
    <div className='mainsec'>
      <div className="sec1">
        <p>{test}</p>
      </div>
      <div className="sec2">
        <div className='img'>
          <img src={imgUrl} alt="" />
          <div className='studentname'>{studentName}</div>
          <div className='coursename'>( {studentCourse} )</div>
        </div>
      </div>
    </div>
  );
}
  
export default TestimonialWidget;