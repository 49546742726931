import '../styles/Counts.css';
import emblem from '../assets/emblem.png'
import React from "react";

function Counts(props){
    return(
        <div className="countssection">
            <div className="sec1">
                <div className="texts">
                    <div className="dot"></div>
                    <div className="span">Nilambur, Malappuram</div> 
                </div>
                <div className="emblem">
                    <img src={emblem} alt=''></img>
                </div>
            </div>
            <div className="sec2">
                    <div className="count">
                        <div className="text">
                            <span className='number'>{props.counts?.student}+</span><br/>
                            <span className='string'>Students</span>
                        </div>
                        <div className="verticalbar"></div>
                    </div>
                    <div className="count">
                        <div className="text">
                            <span className='number'>{props.counts?.faculty}+</span><br/>
                            <span className='string'>Faculties</span>
                        </div>
                        <div className="verticalbar"></div>
                    </div>
                    <div className="count">
                        <div className="text">
                            <span className='number'>{props.counts?.mentors}+</span><br/>
                            <span className='string'>Mentors</span>
                        </div>
                        <div className="verticalbar"></div>
                    </div>
                    <div className="count">
                        <div className="text">
                            <span className='number'>{props.counts?.courses}+</span><br/>
                            <span className='string'>Courses</span>
                        </div>
                    </div>     
            </div>
        </div>
    )
}
export default Counts;