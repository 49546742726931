import { Helmet } from 'react-helmet';
import React, { useState, useRef } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function AddResult() {
    const [studentName, setstudentName] = useState('');
    const [registerNumber, setregisterNumber] = useState('');
    const [fileName, setFileName] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [course, setcourse] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("image", fileName);
        formData.append("studentName", studentName);
        formData.append("registerNumber", registerNumber);
        formData.append("course", course);
        axios.post(
            `${baseUrl}/admin/add-result`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(result => {
            setstudentName('');
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            setregisterNumber('');
            setIsLoading(false);
        }).catch(error => {
            alert('Error!');
            setIsLoading(false);
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFileName(file);
    };

    return (
        <>
            <Sidebar />
            <AdminNav />
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="addpage">
                {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
                <form className="adminform" onSubmit={handleSubmit}>
                    <div className="pagehead"><span>Add Result</span></div>
                    <div className="field">
                        <div className='label' htmlFor="">Name of Student</div>
                        <div className="input"><input type="text" name='studentName' value={studentName} onChange={(e) => setstudentName(e.target.value)} required="required" placeholder='Enter Name of Student' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Register Number</div>
                        <div className="input"><input type="text" name='registerNumber' value={registerNumber} onChange={(e) => setregisterNumber(e.target.value)} required="required" placeholder='Enter Register Number' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Course</div>
                        <div className="input"><input type="text" name='course' value={course} onChange={(e) => setcourse(e.target.value)} required="required" placeholder='Enter Course' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">File</div>
                        <div className="input"><input type="file" id="fileInput" onChange={handleImageChange} accept='.pdf' required="required" ref={fileInputRef} /></div>
                    </div>
                    <button type='submit'>UPLOAD</button>
                </form>
            </div>
            <AdminFooter />
        </>
    );
}

export default AddResult;
