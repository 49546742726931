import React, { useState,useCallback,useEffect } from 'react';
import '../styles/HomeTestimonial.css';
import TestimonialWidget from './TestimonialWidget';

function HomeTestimonial(props) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationClass, setAnimationClass] = useState('');

    const goToNext = useCallback(() => {
        setAnimationClass('slide-out-left');
        setTimeout(() => {
            const isLastImage = currentIndex === props.testimonials?.length - 1;
            const newIndex = isLastImage ? 0 : currentIndex + 1;
            setCurrentIndex(newIndex);
            setAnimationClass('slide-in-right');
        }, 500);
      },[currentIndex, props.testimonials?.length]);
      
      useEffect(() => {
        const interval = setInterval(goToNext, 3000); 
        return () => clearInterval(interval);
      }, [goToNext]);
    return (
        <div className="hometestimonial">
            <span className='testihead'>Student Testimonials</span>
            <div className="testimonial">
                <div className={`testimonial-widget ${animationClass}`} key={currentIndex}>
                    <TestimonialWidget testimonial={props.testimonials[currentIndex]} />
                </div> 
            </div>
        </div>
    );
}

export default HomeTestimonial;