import '../../styles/admin/AdminFooter.css';

function AdminFooter() {
  return (
    <div className="adminfooter">
      Copyright ©2024 Nilambur Skills Foundation
    </div>
  );
}

export default AdminFooter;