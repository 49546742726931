import React from "react";
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import LoadingSpinner from '../innerComponents/LoadingSpinner';
import '../styles/News.css';

function News(props) {
  const { contacts, news } = props;
  const sortedEvents = news?.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <>
      {contacts && news ? (
        <>
          <Helmet>
            <title>NEWS - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Latest News</span>
          </div>
          <div className='newsmain'>
            {sortedEvents?.length > 0 && (
              <div className="mainnewscard">
                <div className="mainnewsimg">
                  <img src={sortedEvents[0]?.imgUrl} alt={sortedEvents[0]?.imageurl} />
                </div>
                <div className="mainnewstext">
                  {sortedEvents[0]?.news} for 2024/2025 Batch
                  <div className="mainnewsdate">
                    {new Date(sortedEvents[0]?.date).toLocaleDateString()}
                  </div>
                </div>
              </div>
            )}
            {sortedEvents?.map((item, index) => (
              <div className="newscard" key={index}>
                <div className="newsimg">
                  <img src={item.imgUrl} alt={item.imageurl} />
                </div>
                <div className="newstext">
                  {item.news} for 2024/2025 Batch
                  <div className="newsdate">
                    {new Date(item.date).toLocaleDateString()}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <Helmet>
            <title>NEWS - NSF</title>
          </Helmet>
          <NavBar />
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Latest News</span>
          </div>
          <LoadingSpinner />
          <Footer/>
        </>
      )}
    </>
  );
}

export default News;
