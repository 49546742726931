import React from 'react';
import Counts from '../innerComponents/Counts';
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import LoadingSpinner from '../innerComponents/LoadingSpinner';

function About(props) {
  const { contacts, about, counts } = props;

  return (
    <>
      {contacts && about && counts ? (
        <>
          <Helmet>
            <title>ABOUT US - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>About Us</span>
          </div>
          <div className='row mt-5 ms-3'>
            <div className='col-lg-6' style={{ margin: '0 10px' }}>
              <h1>{about.heading}</h1>
              <p style={{ letterSpacing: '1px' }}>
                {about.about}
              </p>
            </div>
            <div className='aboutcount'>
              <Counts counts={counts} />
            </div>
          </div>
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <Helmet>
            <title>ABOUT US - NSF</title>
          </Helmet>
          <NavBar/>
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>About Us</span>
          </div>
          <LoadingSpinner />
          <Footer/>
        </>
      )}
    </>
  );
}

export default About;
