import React from "react";
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import HomeApplySec from "../innerComponents/HomeApplySec";
import '../styles/Placement.css';
import LoadingSpinner from '../innerComponents/LoadingSpinner';

function Placements(props) {
  const { contacts, placement } = props;

  return (
    <>
      {contacts && placement ? (
        <>
          <Helmet>
            <title>Placements - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className="headpage">
            <span>Placements</span>
          </div>
          <div className="placementmain">
            {placement.length > 0 ? (
              <div className="placementcardmain">
                {placement.map((item, index) => (
                  <div key={index} className="placementcard">
                    <div className="placementimage">
                      <img src={item.imgUrl} alt={item.imageurl} />
                    </div>
                    <div className="placementname">{item.studentName}</div>
                    <div className="placementcourse">({item.studentCourse})</div>
                    <div className="placementpos">Placed at {item.placedAt} as {item.role}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-placement">
                <h1>No Data.</h1>
              </div>
            )}
          </div>
          <HomeApplySec />
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <Helmet>
            <title>Placements- NSF</title>
          </Helmet>
          <NavBar />
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Placements NSF</span>
          </div>
          <LoadingSpinner />
          <HomeApplySec/>
          <Footer/>
        </>
      )}
    </>
  );
}

export default Placements;
