import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Banner.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function ViewBanner() {
    const [data, setData] = useState({ images: [] });
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/admin/get-banner`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };
        fetchData();
    }, []);

    const deleteBanner = async (id, e) => {
        e.preventDefault();
        setIsDeleting(true);
        try {
            await axios.delete(`${baseUrl}/admin/delete-banner?id=${id}`);
            setData(prevData => ({
                ...prevData,
                images: prevData.images.filter(fac => fac._id !== id)
            }));
        } catch (error) {
            alert('Error!');
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <Sidebar />
            <AdminNav />
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="adminbanner">
                {isDeleting && <div className="loading-overlay"><LoadingSpinner /></div>}
                <div className={`viewtable ${isDeleting ? 'blur' : ''}`}>
                    <div className="pagehead"><span>Banners</span></div>
                    {data.images?.map((item, index) => (
                        <div className="viewcard" key={index}>
                            <img src={item.imgUrl} alt={item.imageurl} />
                            <div className="buttons">
                                <button className='actionbutton delete' onClick={(e) => deleteBanner(item._id, e)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <AdminFooter />
        </>
    );
}

export default ViewBanner;
