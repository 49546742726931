import '../styles/App.css';

function Error(){
    return(
        <>
            <div className="errorpage">
                <h1>ERROR!</h1>
            </div>
        </>
    )
}
export default Error;