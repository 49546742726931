import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import HomeApplySec from '../innerComponents/HomeApplySec';
import LoadingSpinner from '../innerComponents/LoadingSpinner';

function Courses(props) {
  const { contacts } = props;

  return (
    <>
      {contacts ? (
        <>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Courses</span>
          </div>
          <Outlet />
          <HomeApplySec />
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <NavBar />
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Courses</span>
          </div>
          <LoadingSpinner />
          <HomeApplySec />
          <Footer contacts={contacts} />
        </>
      )}
    </>
  );
}

export default Courses;
