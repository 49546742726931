import React, { useState, useEffect } from 'react';
import '../styles/HomeBannerNew.css';

const HomeBannerNew = (props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // const [playAnimation, setPlayAnimation] = useState(false);
  useEffect(() => {    
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % props.banners.length);
      // setPlayAnimation(true); 
      // setTimeout(() => {
      //   setPlayAnimation(false);
      // }, 800);
    };
    const timerId = setInterval(changeImage, 4500);
    return () => clearInterval(timerId);
  }, [props.banners]);

  // useEffect(() => {    
  //   const changeImage = () => {
  //     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % props.banners.length);
  //   };
  //   const timerId = setInterval(changeImage, 3000);
  //   return () => clearInterval(timerId);
  // }, [props.banners]);

  // return (props.banners.length > 0 ? (
  //   <div className="homenew-banner">
  //     <img
  //       src={props.banners[currentImageIndex].imgUrl}
  //       alt={`Banner ${currentImageIndex}`}
  //     />
  //   </div>
  // ) : null);

  return ((props.banners.length>0)?
    <div className="homenew-banner">
      <img src={props.banners[currentImageIndex].imgUrl} alt={`Banner ${currentImageIndex}`}/>
      {/* <img src={props.banners[currentImageIndex].imgUrl} alt={`Banner ${currentImageIndex}`} className={playAnimation ? 'fade-in-animation' : ''}/> */}
    </div>:<></>
  );
};

export default HomeBannerNew;

