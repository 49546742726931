import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from "react";
import Sidebar from './SideBar';
import '../../styles/admin/Form.css';
import AdminNav from './AdminNav';
import AdminFooter from './AdminFooter';
import axios from 'axios';
import LoadingSpinner from '../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../data/data';

function EditCounts() {
    const [student, setstudent] = useState('');
    const [faculty, setfaculty] = useState('');
    const [mentors, setmentors] = useState('');
    const [courses, setcourses] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        axios.get(`${baseUrl}/admin/get-counts`)
            .then(response => {
                if (response.data) {
                    setstudent(response.data?.student || '');
                    setfaculty(response.data?.faculty || '');
                    setmentors(response.data?.mentors || '');
                    setcourses(response.data?.courses || '');
                }
            })
            .catch(error => {
                console.error('Error', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.put(
            `${baseUrl}/admin/edit-counts`,
            { student, faculty, mentors, courses }
        ).then(result => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            alert('Error!')
        });
    };

    return (
        <>
        <Sidebar/>
        <AdminNav/>
        <Helmet>
            <title>Admin - Nilambur Skills Foundations</title>
        </Helmet>
        <div className="addpage">
            {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
            <form className="adminform" onSubmit={handleSubmit}>
            <div className="pagehead"><span>Counts</span></div>
                <div className="field">
                    <div className='label' htmlFor="">Student Count</div>
                    <div className="input"><input type="number" name='student' value={student} onChange={(e) => setstudent(e.target.value)} required="required" placeholder='Enter Student Count'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Faculty Count</div>
                    <div className="input"><input type="number" name='faculty' value={faculty} onChange={(e) => setfaculty(e.target.value)} required="required" placeholder='Enter Faculty Count'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Mentor Count</div>
                    <div className="input"><input type="number" name='mentors' value={mentors} onChange={(e) => setmentors(e.target.value)} required="required" placeholder='Enter Mentor Count'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Course Count</div>
                    <div className="input"><input type="number" name='courses' value={courses} onChange={(e) => setcourses(e.target.value)} required="required" placeholder='Enter Course Count'/></div>
                </div>
                <button type='submit'>EDIT</button>
            </form>
        </div>
        <AdminFooter/>
        </>
    );
}

export default EditCounts;
