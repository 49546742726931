import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from "react";
import Sidebar from './SideBar';
import '../../styles/admin/Form.css';
import AdminNav from './AdminNav';
import AdminFooter from './AdminFooter';
import axios from 'axios';
import LoadingSpinner from '../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../data/data';

function EditAboutPage() {
    const [heading, setHeading] = useState('');
    const [about, setAbout] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        axios.get(`${baseUrl}/admin/get-about-page`)
            .then(response => {
                if (response.data) {
                    setHeading(response.data?.heading || '');
                    setAbout(response.data?.about || '');
                }
            })
            .catch(error => {
                console.error('Error', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.put(
            `${baseUrl}/admin/edit-about-page`,
            { heading, about }
        ).then(result => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            alert('Error!');
        });
    };

    return (
        <>
        <Sidebar/>
        <AdminNav/>
        <Helmet>
            <title>Admin - Nilambur Skills Foundations</title>
        </Helmet>
        <div className="addpage">
            {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
            <form className="adminform" onSubmit={handleSubmit}>
                <div className="pagehead"><span>About</span></div>
                <div className="field">
                    <div className='label' htmlFor="">Head</div>
                    <div className="input"><input type="text" name='heading' value={heading} onChange={(e) => setHeading(e.target.value)} required="required" placeholder='Enter Head'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">About</div>
                    <div className="textarea"><textarea rows='4' name='about' value={about} onChange={(e) => setAbout(e.target.value)} required="required" placeholder='Enter About'/></div>
                </div>
                <button type='submit'>EDIT</button>
            </form>
        </div>
        <AdminFooter/>
        </>
    );
}

export default EditAboutPage;
