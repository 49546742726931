import '../styles/CallButton.css';
import { Link } from 'react-router-dom';
import { MdPhone } from 'react-icons/md';
import React from "react";

function CallButton(props){
    return(
        <div className="call">
            <Link to={`tel:+91${props.contacts?.mobile1}`} className="btn">
                <MdPhone/>
            </Link>
        </div>
    )
}
export default CallButton;