import React from "react";
import '../styles/notifications.css';
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import LoadingSpinner from '../innerComponents/LoadingSpinner';

function Notifications(props) {
  const { contacts, notifications } = props;

  return (
    <>
      {contacts && notifications ? (
        <>
          <Helmet>
            <title>Notifications - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>All Notifications</span>
          </div>
          {notifications.length <= 0 ? (
            <div className="nonotifications">
              No Notifications!
            </div>
          ) : (
            <>
            <div  className="notificationsmain">
              {notifications.map((item, index) => (
                <div className='notificationcard' key={index}>
                  <span className='head'>{item.title}</span>
                  <div className='dateandtype'>
                    <span className='date'>{item.date}</span>
                    <div className="vert"></div>
                    <span className='type'>{item.category.toUpperCase()}</span>
                  </div>
                  <span className='content'>{item.description}</span>
                </div>
              ))}
            </div>
            </>
          )}
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <Helmet>
            <title>Notifications - NSF</title>
          </Helmet>
          <NavBar />
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>All Notifications</span>
          </div>
          <LoadingSpinner />
          <Footer/>
        </>
      )}
    </>
  );
}

export default Notifications;
