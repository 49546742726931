import React, { useEffect, useRef } from 'react';
import '../styles/Slider.css';

const Slider = () => {
  const logosRef = useRef(null);

  useEffect(() => {
    const copy = logosRef.current.cloneNode(true);
    logosRef.current.parentNode.appendChild(copy);
  }, []);

  return (
    <div className="logos">
      <div ref={logosRef} className="logos-slide sl">
        <div className="logo-text sl">Nilambur Skills Foundations</div>
        <div className="dot"></div>
        <div className="logo-text sl">Nilambur Skills Foundations</div>
        <div className="dot"></div>
        <div className="logo-text sl">Nilambur Skills Foundations</div>
        <div className="dot"></div>
        <div className="logo-text sl">Nilambur Skills Foundations</div>
        <div className="dot"></div>
        <div className="logo-text sl">Nilambur Skills Foundations</div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default Slider;