import React from "react";
import { MdArrowForward, MdEmail, MdPhone } from 'react-icons/md';
import { FaLocationDot } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import LoadingSpinner from '../innerComponents/LoadingSpinner';
import '../styles/Contact.css';

export default function Contact(props) {
  const { contacts } = props;

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "d00347c9-5fcc-4995-a5f9-906cfacbd253");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      event.target.reset();
    } else {
      alert("Error!");
    }
  };

  return (
    <>
      {contacts ? (
        <>
          <Helmet>
            <title>CONTACT - NSF</title>
          </Helmet>
          <NavBar contacts={contacts} />
          <ApplyButton />
          <CallButton contacts={contacts} />
          <WhatsappButton contacts={contacts} />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Contact Us</span>
          </div>
          <form onSubmit={onSubmit} className="contactmain">
            <div className="contacthead">Send Your Enquiries.</div>
            <div className="contacttextboxes">
              <div className="textbox">
                <div className="inner">
                  <input name="Name" required="required" type="text" placeholder='Name' />
                </div>
              </div>
              <div className="textbox">
                <div className="inner">
                  <input name="Email" required="required" type="email" placeholder='Email' />
                </div>
              </div>
              <div className="textbox">
                <div className="inner">
                  <input name="Phone" required="required" type="text" placeholder='Telephone No' />
                </div>
              </div>
              <div className="textbox">
                <div className="inner">
                  <input name="Subject" required="required" type="text" placeholder='Subject' />
                </div>
              </div>
            </div>
            <div className="contacttextare">
              <div className="textarea">
                <textarea rows='5' name="Message" required="required" placeholder='Message'></textarea>
              </div>
            </div>
            <button type='submit' className="contactsendbutton">
              SEND NOW <MdArrowForward style={{ fontSize: '1.5rem', marginLeft: '20px' }} />
            </button>
          </form>
          <div className="contactaddress">
            <div className="address">
              <div className="addresscard">
                <div className="addresshead">Address</div>
                <div className="horizontalline"></div>
                <div className="place">
                  <div className="icon">
                    <div className="ic"><FaLocationDot /></div>
                  </div>
                  <span>{contacts.address}</span>
                </div>
                <div className="horizontalline"></div>
                <div className="site">
                  <div className="icon">
                    <div className="ic"><MdEmail /></div>
                  </div>
                  <span>{contacts.email}</span>
                </div>
                <div className="horizontalline"></div>
                <div className="phone">
                  <div className="icon">
                    <div className="ic"><MdPhone /></div>
                  </div>
                  <span>+91{contacts.mobile1} / +91{contacts.mobile2}</span>
                </div>
              </div>
            </div>
            <div className="location">
              <div className="locationcard">
                <iframe
                  width="100%"
                  title='nilambur'
                  style={{ borderRadius: '20px' }}
                  height="400px"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Nilambur+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
          <Footer contacts={contacts} />
        </>
      ) : (
        <>
          <Helmet>
            <title>CONTACT - NSF</title>
          </Helmet>
          <NavBar />
          <ApplyButton />
          <ScrollTopButton />
          <div className='headpage'>
            <span>Contact Us</span>
          </div>
          <form onSubmit={onSubmit} className="contactmain">
            <div className="contacthead">Send Your Enquiries.</div>
            <div className="contacttextboxes">
              <div className="textbox">
                <div className="inner">
                  <input name="Name" required="required" type="text" placeholder='Name' />
                </div>
              </div>
              <div className="textbox">
                <div className="inner">
                  <input name="Email" required="required" type="email" placeholder='Email' />
                </div>
              </div>
              <div className="textbox">
                <div className="inner">
                  <input name="Phone" required="required" type="text" placeholder='Telephone No' />
                </div>
              </div>
              <div className="textbox">
                <div className="inner">
                  <input name="Subject" required="required" type="text" placeholder='Subject' />
                </div>
              </div>
            </div>
            <div className="contacttextare">
              <div className="textarea">
                <textarea rows='5' name="Message" required="required" placeholder='Message'></textarea>
              </div>
            </div>
            <button type='submit' className="contactsendbutton">
              SEND NOW <MdArrowForward style={{ fontSize: '1.5rem', marginLeft: '20px' }} />
            </button>
          </form>
          <LoadingSpinner />
          <Footer/>
        </>
      )}
    </>
  );
}
