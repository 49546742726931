import React from "react";
import '../styles/Footer.css';
import { FaInstagram } from 'react-icons/fa';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Slider from './Slider';
import { FaFacebook, FaPhone, FaWhatsapp } from 'react-icons/fa6';

const Footer = (props) => {
    const onSubmit = async (event) => {
        event.preventDefault();
        const formDta = new FormData(event.target);
    
        formDta.append("access_key", "d00347c9-5fcc-4995-a5f9-906cfacbd253");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formDta
        });
    
        const dta = await response.json();
    
        if (dta.success) {
          event.target.reset();
        } else {
          alert("Error!");
        }
    };
    return (
        <footer className="footer">
            <Slider/>
            <div className="horizontal"></div>
            <div className="horizontal"></div>
            <div className="sec1">
                <div className="child-sec">
                    <span className='span'>For more in-depth course descriptions, feel free to reach out.</span>
                    <Link to='/news' style={{textDecoration:'none'}}><div className="morebutton">READ ALL NEWS<span className='btnarrow'><MdArrowForward/></span></div></Link>
                </div>
                <div className="child-sec">
                    <div className='heading'><span>Quick Links</span></div>
                    <Link to='/' className='link'><span>Home</span></Link>
                    <Link to='/about' className='link'><span>About</span></Link>
                    <Link to='/courses' className='link'><span>Courses</span></Link>
                    <Link to='/contact' className='link'><span>Contact</span></Link>
                    <Link to='/team' className='link'><span>Team</span></Link>
                    <Link to='/news' className='link'><span>News</span></Link>
                </div>
                {props.contacts ? (
                    <div className="child-sec">
                        <div className='heading'><span>Contacts</span></div>
                        <div className='link'><span>{props.contacts?.address}</span></div>
                        <div className='link'><span>{props.contacts?.email}</span></div>
                        <div className='link'><span>+91{props.contacts?.mobile1}</span></div>
                        <div className='link'><span>+91{props.contacts?.mobile2}</span></div>
                    </div>
                    ):(
                    <div className="child-sec">
                        <div className='heading'><span>Contacts</span></div>
                        <div className='link'><span>Nilambur skills Foundations Door no 82, Amal College of Advanced Studies, Eranhimangad P.O., Myladi, Nilambur, Malappuram, Kerala, 677329</span></div>
                        <div className='link'><span>nsfofficial01@gmail.com</span></div>
                        <div className='link'><span>+919744437427</span></div>
                        <div className='link'><span>+916282858473</span></div>
                    </div>
                )}
                <form onSubmit={onSubmit} className="child-sec">
                    <div className='heading'><span>Request Callback</span></div>
                    <div className="link"><span>Please give us a number and we’ll call back at your convenience.</span></div>
                    <div className="callinput">
                        <div className="input"><input type="text" name="Phone-Call-Back-Request" required="required" placeholder='Enter Phone Number'/></div>
                        <button type="submit" className="btn"><MdArrowForward/></button>
                    </div>
                </form>
            </div>
            <div className="horizontal"></div>
            <div className="horizontal"></div>
            <div className='sec2'>
                <span>Copyright ©2024 Nilambur Skills Foundation</span>
                {props.contacts ? (
                <div className='icons'>
                    <Link to={props.contacts?.instagram} className="icon"><FaInstagram/></Link>
                    <Link to={props.contacts?.facebook} className="icon"><FaFacebook/></Link>
                    <Link to={props.contacts?.whatsapp} className="icon"><FaWhatsapp/></Link>
                    <Link to={`tel:+91${props.contacts?.mobile2}`} className="icon"><FaPhone/></Link>
                </div>
                ):(<></>)}
            </div>
        </footer>
    );
}

export default Footer;