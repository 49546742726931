import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from "react";
import Sidebar from './SideBar';
import '../../styles/admin/Form.css';
import AdminNav from './AdminNav';
import AdminFooter from './AdminFooter';
import axios from 'axios';
import LoadingSpinner from '../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../data/data';

function EditContacts() {
    const [email, setemail] = useState('');
    const [mobile1, setMobile1] = useState('');
    const [mobile2, setMobile2] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [address, setaddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        axios.get(`${baseUrl}/admin/get-contact-details`)
            .then(response => {
                if(response.data){
                    setemail(response.data?.email || '');
                    setMobile1(response.data?.mobile1 || '');
                    setMobile2(response.data?.mobile2 || '');
                    setWhatsapp(response.data?.whatsapp || '');
                    setInstagram(response.data?.instagram || '');
                    setFacebook(response.data?.facebook || '');
                    setaddress(response.data?.address || '');
                }
            })
            .catch(error => {
                console.error('Error', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.put(
            `${baseUrl}/admin/edit-contact-details`,
            { email, mobile1, mobile2, whatsapp, instagram, facebook, address }
        ).then(result => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            alert('Error!')
        });
    };

    return (
        <>
        <Sidebar/>
        <AdminNav/>
        <Helmet>
            <title>Admin - Nilambur Skills Foundations</title>
        </Helmet>
        <div className="addpage">
            {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
            <form className="adminform" onSubmit={handleSubmit}>
            <div className="pagehead"><span>Contact Details</span></div>
                <div className="field">
                    <div className='label' htmlFor="">Email</div>
                    <div className="input"><input type="text" name='email' value={email} onChange={(e) => setemail(e.target.value)} required="required" placeholder='Enter Email Id'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Mobile Number 1</div>
                    <div className="input"><input type="text" name='mobile1' value={mobile1} onChange={(e) => setMobile1(e.target.value)} required="required" placeholder='Enter Mobile Number 1'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Mobile Number 2</div>
                    <div className="input"><input type="text" name='mobile2' value={mobile2} onChange={(e) => setMobile2(e.target.value)} required="required" placeholder='Enter Mobile Number 2'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Address</div>
                    <div className="textarea"><textarea rows='4' name='address' value={address} onChange={(e) => setaddress(e.target.value)} required="required" placeholder='Enter address'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Whatsapp</div>
                    <div className="input"><input type="text" name='whatsapp' value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} required="required" placeholder='Enter Whatsapp Number'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Instagram</div>
                    <div className="input"><input type="text" name='instagram' value={instagram} onChange={(e) => setInstagram(e.target.value)} required="required" placeholder='Enter Instagram Url'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Facebook</div>
                    <div className="input"><input type="text" name='facebook' value={facebook} onChange={(e) => setFacebook(e.target.value)} required="required" placeholder='Enter Facebook Url'/></div>
                </div>
                <button type='submit'>EDIT</button>
            </form>
        </div>
        <AdminFooter/>
        </>
    );
}

export default EditContacts;
