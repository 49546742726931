import { MdArrowForward } from 'react-icons/md';
import '../styles/QuickEnquiry.css';

const QuickEnquiry = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "d00347c9-5fcc-4995-a5f9-906cfacbd253");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      event.target.reset();
    } else {
      alert("Error");
    }
  };
  return (
    <>
        <form onSubmit={onSubmit} className="quickmain">
            <div className="quickhead">QUICK ENQUIRY</div>
            <div className="quicktextboxes">
                <div className="textbox"><div className="inner"><input name="Name" required="required" type="text" placeholder='Name'/></div></div>
                <div className="textbox"><div className="inner"><input name="Email" required="required" type="email" placeholder='Email'/></div></div>
                <div className="textbox"><div className="inner"><input name="Phone" required="required" type="text" placeholder='Mobile'/></div></div>
            </div>
            <div className="quicktextare">
                <div className="textarea"><textarea name="Message" required="required" rows='5' placeholder='Message'></textarea></div>
            </div>
            <button type='submit' className="quicksendbutton">SEND NOW <MdArrowForward style={{fontSize:'1.5rem',marginLeft:'20px'}}/></button>
        </form>
    </>
  );
};

export default QuickEnquiry;