import React from 'react';
import HomeCard from '../innerComponents/HomeCard';
import Counts from '../innerComponents/Counts';
import HomeAbout1 from '../innerComponents/HomeAbout1';
import HomeTestimonial from '../innerComponents/HomeTestimonial';
import HomeAbout2 from '../innerComponents/HomeAbout2';
import HomeApplySec from '../innerComponents/HomeApplySec';
import HomeNewsEvents from '../innerComponents/HomeNewsEvents';
import '../styles/Home.css';
import HomeBannerNew from '../innerComponents/HomeBannerNew';
import { Helmet } from 'react-helmet';
import NavBar from '../innerComponents/NewNav';
import Footer from '../innerComponents/Footer';
import WhatsappButton from '../innerComponents/WhatsappButton';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import CallButton from '../innerComponents/CallButton';
import ApplyButton from '../innerComponents/ApplyButton';
import LogoSlider from '../innerComponents/LogoSlider';
import LoadingSpinner from '../innerComponents/LoadingSpinner';
import HomeMessage from '../innerComponents/HomeMessage';

function Home(props) {
  const { contacts, counts, about, testimonials, news, banners } = props;

  return (
    <>
      {contacts && counts && about && testimonials && news && banners  ? (
        <div className="home">
          <Helmet>
            <title>Nilambur Skills Foundations</title>
          </Helmet>
          <NavBar contacts={contacts}/>
          <ApplyButton/>
          <CallButton contacts={contacts}/>
          <WhatsappButton contacts={contacts}/>
          <ScrollTopButton/>
          <HomeBannerNew banners={banners}/>
          <HomeMessage/>
          <HomeCard/>
          <div className='homecountabout'>
            <Counts counts={counts}/>
            <HomeAbout1 about={about}/>
          </div>
          <HomeAbout2/>
          <HomeTestimonial testimonials={testimonials}/>
          <HomeApplySec/>
          <HomeNewsEvents news={news}/>
          <LogoSlider/>
          <Footer contacts={contacts}/>
        </div>
      ) : (
        <div className="home">
          <Helmet>
            <title>Nilambur Skills Foundations</title>
          </Helmet>
          <NavBar/>
          <ApplyButton/>
          <ScrollTopButton/>
          <LoadingSpinner />
          <Footer/>
        </div>
      )}
    </>
  );
}

export default Home;
