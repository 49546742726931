import { Helmet } from 'react-helmet';
import React, {useEffect, useState} from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function ViewTestimonials() {
    const [data, setData] = useState({ images: [] });
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await axios.get(`${baseUrl}/admin/get-testimonial`);
                setData(response.data);
            }catch(error){
                console.error('Error! ', error);
            }
        };
        fetchData();
        return () => {
        };
    }, []);

    const deleteTeamMember = async (id, e) => {
        e.preventDefault();
        setIsDeleting(true);
        try {
            await axios.delete(`${baseUrl}/admin/delete-testimonial?id=${id}`);
            setData(prevData => ({
                ...prevData,
                images: prevData.images.filter(tes => tes._id !== id)
            }));
        } catch (error) {
            alert('Error!');
        } finally {
            setIsDeleting(false);
        }
    };
    
    return (
        <>
            <Sidebar/>
            <AdminNav/>
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="addpage">
                {isDeleting && <div className="loading-overlay"><LoadingSpinner /></div>}     
                <div className={`viewtable ${isDeleting ? 'blur' : ''}`}>
                    <div className="pagehead"><span>All Testimonials</span></div>
                    {data.images?.map((item, index)=>(
                        <div className="viewcard" key={index}>
                            <div className="item">
                                <span className="head">Student Name</span>
                                <span className="content">{item.studentName}</span>
                            </div>
                            <div className="item">
                                <span className="head">Image</span>
                                <span className="content"><img style={{height:'150px',width:'150px',objectFit:'contain'}} src={item.imgUrl} alt={item.imageurl} /></span>
                            </div>
                            <div className="item">
                                <span className="head">Course</span>
                                <span className="content">{item.studentCourse}</span>
                            </div>
                            <div className="item">
                                <span className="head">Testimonial</span>
                                <span className="content">{item.test}</span>
                            </div>
                            <div className="buttons">
                                <Link className='btnlink' to={`/admin/edit-testimonial/${item._id}/${encodeURIComponent(item.imgUrl)}`}><button className='actionbutton edit'>Edit</button></Link><br/>
                                <button className='actionbutton delete' onClick={(e) => deleteTeamMember(item._id, e)}>Delete</button>
                            </div>
                        </div>
                    ))} 
                </div>
            </div>
            <AdminFooter/>
        </>
    );
}

export default ViewTestimonials;