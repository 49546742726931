import { Link } from 'react-router-dom';
import '../styles/HomeApplySec.css';
import { MdArrowForward } from 'react-icons/md';

function HomeApplySec(){
    return(
        <div className="homeapplysec">
            <div className="sec">
                <div className="texts">
                    <span className='wantclear'>Unlock Your Future with Our</span>
                    <span className='medeng'>DIPLOMA / PG DIPLOMA</span>
                    <span className='entrance'>Courses</span>
                    <Link to='/contact' className='button' style={{textDecoration:'none'}}>Apply Now <div className="arrow"><MdArrowForward/></div></Link>
                </div>
            </div>
        </div>
    )
}
export default HomeApplySec;