import { Helmet } from 'react-helmet';
import React, { useState } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function AddCourse() {
    const [coursename, setCourse] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState('');
    const options = [
      { id: '1', value: 'PG Diploma Courses' },
      { id: '2', value: 'Diploma Courses' },
      { id: '3', value: 'Others' },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(
            `${baseUrl}/admin/add-course`,
            { coursename, description, category }
        ).then(result => {
            setCourse('');
            setDescription('');
            setCategory('');
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            alert('Error!')
        });
    };

    return (
        <>
        <Sidebar/>
        <AdminNav/>
        <Helmet>
            <title>Admin - Nilambur Skills Foundations</title>
        </Helmet>
        <div className="addpage">
            {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
            <form className="adminform" onSubmit={handleSubmit}>
            <div className="pagehead"><span>Add Course</span></div>
                <div className="field">
                    <div className='label' htmlFor="">Course Name</div>
                    <div className="input"><input type="text" name='coursename' value={coursename} onChange={(e) => setCourse(e.target.value)} required="required" placeholder='Enter Course Name'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Description</div>
                    <div className="textarea"><textarea rows='4' name='description' value={description} onChange={(e) => setDescription(e.target.value)} required="required" placeholder='Enter Description'/></div>
                </div>
                <div className="field">
                    <div className='label' htmlFor="">Category</div>
                    <div className="input">
                        <input type="text" id="category" name='category' list="options" value={category} onChange={(e) => {setCategory(e.target.value)}} required="required" placeholder='Choose a Category'/>
                        <datalist id="options">
                            {options.map((option) => (
                                <option key={option.id} value={option.value} />
                            ))}
                        </datalist>
                    </div>
                </div>
                <button type='submit'>ADD</button>
            </form>
        </div>
        <AdminFooter/>
        </>
    );
}

export default AddCourse;