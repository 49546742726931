import { Helmet } from 'react-helmet';
import React, { useState, useEffect, useRef } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function EditPlacement() {
    const { id, img } = useParams();
    const navigate = useNavigate();

    const [placedAt, setplacedAt] = useState('');
    const [role, setRole] = useState('');
    const [studentName, setstudentName] = useState('');
    const [studentCourse, setstudentCourse] = useState('');
    const [fileName, setFileName] = useState();
    const [selectedImage, setSelectedImage] = useState(img);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        axios.get(`${baseUrl}/admin/get-placement-details?id=${id}`)
            .then(response => {
                setplacedAt(response.data.placedAt);
                setRole(response.data.role);
                setstudentName(response.data?.studentName);
                setstudentCourse(response.data?.studentCourse);
            })
            .catch(error => {
                console.error('Error', error);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        if (fileName) {
            formData.append("image", fileName);
        }
        formData.append("placedAt", placedAt);
        formData.append("role", role);
        formData.append("studentName", studentName);
        formData.append("studentCourse", studentCourse);
        axios.put(
            `${baseUrl}/admin/edit-placement?id=${id}`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(result => {
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            setIsLoading(false);
            navigate('/admin/view-placement');
        }).catch(error => {
            alert(error);
            setIsLoading(false);
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFileName(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Sidebar />
            <AdminNav />
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="addpage">
                {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
                <form className="adminform" onSubmit={handleSubmit}>
                    <div className="pagehead"><span>Edit Placement</span></div>
                    <div className="field">
                        <div className='label' htmlFor="">Name of Student</div>
                        <div className="input"><input type="text" name='studentName' value={studentName} onChange={(e) => setstudentName(e.target.value)} required="required" placeholder='Enter Student Name' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Course of Student</div>
                        <div className="input"><input type="text" name='studentCourse' value={studentCourse} onChange={(e) => setstudentCourse(e.target.value)} required="required" placeholder='Enter Student Course' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Placed At</div>
                        <div className="textarea"><textarea rows='4' name='placedAt' value={placedAt} onChange={(e) => setplacedAt(e.target.value)} required="required" placeholder='Placed At'/></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Job Role</div>
                        <div className="textarea"><textarea rows='4' name='role' value={role} onChange={(e) => setRole(e.target.value)} required="required" placeholder='Job Role'/></div>
                    </div>
                    <div className="imagecontainer">
                        {selectedImage ? (
                            <img src={selectedImage} alt="Selected" className="image" />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Image</div>
                        <div className="input"><input type="file" id="fileInput" onChange={handleImageChange} accept='image/*' ref={fileInputRef}/></div>
                    </div>
                    <button type='submit'>EDIT</button>
                </form>
            </div>
            <AdminFooter />
        </>
    );
}

export default EditPlacement;
