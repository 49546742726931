import React from 'react';
import img from '../assets/director.jpeg';
import '../styles/HomeMessage.css';

function HomeMessage(){
    const managerData = {
        name: 'P. V. Abdul Wahab',
        photo: img,
        message: "'We founded NSF to make sure our students get valuable skills that lead them to meaningful careers. Our unwavering commitment focuses on providing individuals access to career pathways, cultivating the development of a strong workforce and fostering a thriving economy. We are preparing our students for the future. The future job market will be run by those who have skills, and that's where NSF comes in.'",
      };
    return (
        <div className='homemessagecontainer'>
           <div  className='homemessageimage'> 
                <img src={managerData.photo} alt={managerData.name}/>
            </div>
            <div className='secondmessagecontainer'>
                <div className='nameofdirector'>
                    <span className='homemessagename'>{managerData.name}</span><br/>
                    <span className='homemessagerole'>Managing Director</span>
                </div>
                <div className='message'>
                    <p>{managerData.message}</p>
                </div>
            </div>
        </div>
    );
};

export default HomeMessage;
