import { Helmet } from 'react-helmet';
import React, { useState, useEffect, useRef } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';
function EditTeamMember() {
    const { id, img } = useParams();
    const navigate = useNavigate();
    const [facname, setFacname] = useState('');
    const [position, setPosition] = useState('');
    const [fileName, setFileName] = useState();
    const [selectedImage, setSelectedImage] = useState(img);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        axios.get(`${baseUrl}/admin/get-faculty-details?id=${id}`)
            .then(response => {
                setFacname(response.data?.facultyName);
                setPosition(response.data?.position);
            })
            .catch(error => {
                console.error('Error', error);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        if (fileName) {
            formData.append("image", fileName);
        }
        formData.append("facultyName", facname);
        formData.append("position", position);
        axios.put(
            `${baseUrl}/admin/edit-faculty?id=${id}`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(result => {
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            setIsLoading(false);
            navigate('/admin/view-team');
        }).catch(error => {
            alert('Error!');
            setIsLoading(false);
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFileName(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Sidebar />
            <AdminNav />
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="addpage">
                {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
                <form className="adminform" onSubmit={handleSubmit}>
                    <div className="pagehead"><span>Edit Team Member</span></div>
                    <div className="field">
                        <div className='label' htmlFor="">Name of Member</div>
                        <div className="input"><input type="text" name='facname' value={facname} onChange={(e) => setFacname(e.target.value)} required="required" placeholder='Enter Name of Member' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Position</div>
                        <div className="input"><input type="text" name='position' value={position} onChange={(e) => setPosition(e.target.value)} required="required" placeholder='Enter Position' /></div>
                    </div>
                    <div className="imagecontainer">
                        {selectedImage ? (
                            <img src={selectedImage} alt="Selected" className="image" />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Image</div>
                        <div className="input"><input type="file" id="fileInput" onChange={handleImageChange} accept='image/*' ref={fileInputRef}/></div>
                    </div>
                    <button type='submit'>EDIT</button>
                </form>
            </div>
            <AdminFooter />
        </>
    );
}

export default EditTeamMember;
