import { Helmet } from 'react-helmet';
import React, { useState, useEffect, useRef } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';
import { baseUrl } from '../../../data/data';

function EditNews() {
    const { id, img } = useParams();
    const navigate = useNavigate();
    const [news, setnews] = useState('');
    const [date, setdate] = useState('');
    const [fileName, setFileName] = useState();
    const [selectedImage, setSelectedImage] = useState(img);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        axios.get(`${baseUrl}/admin/get-news-details?id=${id}`)
            .then(response => {
                setnews(response.data?.news);
                setdate(response.data?.date);
            })
            .catch(error => {
                console.error('Error', error);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        if (fileName) {
            formData.append("image", fileName);
        }
        formData.append("news", news);
        formData.append("date", date);
        axios.put(
            `${baseUrl}/admin/edit-news?id=${id}`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(result => {
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            setIsLoading(false);
            navigate('/admin/view-news');
        }).catch(error => {
            alert('Error!');
            setIsLoading(false);
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFileName(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Sidebar />
            <AdminNav />
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="addpage">
                {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
                <form className="adminform" onSubmit={handleSubmit}>
                    <div className="pagehead"><span>Edit News</span></div>
                    <div className="field">
                        <div className='label' htmlFor="">News Title</div>
                        <div className="input"><input type="text" name='news' value={news} onChange={(e) => setnews(e.target.value)} required="required" placeholder='Enter News Title' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Date</div>
                        <div className="input"><input type="date" name='date' value={date} onChange={(e) => setdate(e.target.value)} required="required" placeholder='Enter Date' /></div>
                    </div>
                    <div className="imagecontainer">
                        {selectedImage ? (
                            <img src={selectedImage} alt="Selected" className="image" />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Image</div>
                        <div className="input"><input type="file" id="fileInput" onChange={handleImageChange} accept='image/*' ref={fileInputRef}/></div>
                    </div>
                    <button type='submit'>EDIT</button>
                </form>
            </div>
            <AdminFooter />
        </>
    );
}

export default EditNews;
