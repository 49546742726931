import { Helmet } from 'react-helmet';
import React, { useState, useRef } from "react";
import Sidebar from '../SideBar';
import '../../../styles/admin/Form.css';
import AdminNav from '../AdminNav';
import AdminFooter from '../AdminFooter';
import axios from 'axios';
import {baseUrl} from '../../../data/data';
import LoadingSpinner from '../../../innerComponents/LoadingSpinner';

function AddNews() {
    const [news, setnews] = useState('');
    const [date, setdate] = useState('');
    const [fileName, setFileName] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("image", fileName);
        formData.append("news", news);
        formData.append("date", date);
        axios.post(
            `${baseUrl}/admin/add-news`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(result => {
            setnews('');
            setSelectedImage(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            setdate('');
            setIsLoading(false);
        }).catch(error => {
            alert('Error!');
            setIsLoading(false);
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFileName(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Sidebar />
            <AdminNav />
            <Helmet>
                <title>Admin - Nilambur Skills Foundations</title>
            </Helmet>
            <div className="addpage">
                {isLoading && <div className="loading-overlay"><LoadingSpinner /></div>}
                <form className="adminform" onSubmit={handleSubmit}>
                    <div className="pagehead"><span>Add News</span></div>
                    <div className="field">
                        <div className='label' htmlFor="">News Title</div>
                        <div className="input"><input type="text" name='news' value={news} onChange={(e) => setnews(e.target.value)} required="required" placeholder='Enter News Title' /></div>
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Date</div>
                        <div className="input"><input type="date" name='date' value={date} onChange={(e) => setdate(e.target.value)} required="required" placeholder='Enter Date' /></div>
                    </div>
                    <div className="imagecontainer">
                        {selectedImage ? (
                            <img src={selectedImage} alt="Selected" className="image" />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="field">
                        <div className='label' htmlFor="">Image</div>
                        <div className="input"><input type="file" id="fileInput" onChange={handleImageChange} accept='image/*' required="required" ref={fileInputRef} /></div>
                    </div>
                    <button type='submit'>ADD</button>
                </form>
            </div>
            <AdminFooter />
        </>
    );
}

export default AddNews;
