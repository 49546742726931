import '../styles/HomeAbout1.css';
import l1 from '../assets/letters/s2.png';
import l2 from '../assets/letters/n2.png';
import l3 from '../assets/letters/g2.png';
import l4 from '../assets/letters/f2.png';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';

function HomeAbout1(props){
    return(
        <>
        <div className="homeabout1">
            <div className="logosec">
                <img src={l2} alt="letter1" />
                <img src={l1} alt="letter2" />
                <img src={l4} alt="letter3" />
                <img src={l3} alt="letter4" />
            </div>
            <div className="textsec">
                <div className="head">
                    {props.about?.heading}
                </div>
                <div className="content">
                    {props.about?.about}
                </div>
                <Link to='/about' className="allbutton" style={{textDecoration:'none'}}>
                    READ MORE
                    <span className="buttonarrow"><MdArrowForward/></span>
                </Link>
            </div>
        </div>
        </>
    )
}
export default HomeAbout1;